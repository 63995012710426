





































































































































































































import { InputSetups } from '@/mixins/input-setups'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import CloseChatHandlerModuleBuilder
  from '@/includes/logic/Modules/models/modules/CloseChatHandlerModule/CloseChatHandlerModuleBuilder'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import ModuleConfigSettingsWrapper from '@/components/Modules/components/ModuleConfigSettingsWrapper.vue'
import ModuleConfigSettings from '@/components/Modules/components/ModuleConfigSettings.vue'
import { EXPERIMENTAL_TAG } from "@/includes/constants";
import {
  InvertedWeekDaysIndexMap,
  WeekDaysIndexMap
} from "@/includes/logic/Modules/models/modules/CloseChatHandlerModule/types";

import { SelectOption } from 'piramis-base-components/src/logic/types'
import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue'
import TimePickerInput from 'piramis-base-components/src/components/Pi/fields/TimePickerInput/TimePickerInput.vue'
import MultiMessageEditorWithMediaInput
  from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import { MediaEditorMode } from 'piramis-base-components/src/components/NewMessageEditors/types'
import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue'
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'
import TimeUnitInput from 'piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue'
import NestedContent from 'piramis-base-components/src/components/Pi/components/NestedContent.vue'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import WeekDayPickerInput from 'piramis-base-components/src/components/Pi/fields/WeekDayPickerInput/WeekDayPickerInput.vue'

import { Component, Mixins, VModel } from 'vue-property-decorator'
import TimezoneSelect from "@/components/TimezoneSelect.vue";

@Component({
  components: {
    TimezoneSelect,
    SelectInput,
    TimePickerInput,
    MultiMessageEditorWithMediaInput,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    SwitchInput,
    TimeUnitInput,
    NestedContent,
    ConfigField,
    WeekDayPickerInput
  },
  data() {
    return {
      StepUnit,
      MediaEditorMode,
      EXPERIMENTAL_TAG
    }
  }
})
export default class CloseChatHandlerModuleView extends Mixins(ModuleBindings, InputSetups, TariffsTagsHelper) {
  @VModel() module!: CloseChatHandlerModuleBuilder

  closeChatBanTypeOptions: Array<SelectOption> = [
    { label: this.$t('close_chat_type_all'), value: 'All' },
    { label: this.$t('close_chat_type_media'), value: 'Media' },
  ]

  private native = false

  private closeChatType: CloseChatHandlerModuleBuilder['model']['params']['close_chat_type'] = "All"

  get daysDontOpen() {
    return this.module.model.params.days_dont_open.map(value => +InvertedWeekDaysIndexMap[value])
  }

  set daysDontOpen(dayIndex: Array<number>) {
    this.module.model.params.days_dont_open = dayIndex.map(value => WeekDaysIndexMap[value])
  }

  get closeChatTypeVm() {
    return this.closeChatType
  }

  set closeChatTypeVm(value: CloseChatHandlerModuleBuilder['model']['params']['close_chat_type']) {
    if (value === "All") {
      if (this.native) {
        this.module.model.params.close_chat_type = "AllNative"
      } else {
        this.module.model.params.close_chat_type = "All"
      }
    }

    if (value === "Media") {
      if (this.native) {
        this.module.model.params.close_chat_type = "MediaNative"
      } else {
        this.module.model.params.close_chat_type = "Media"
      }
    }

    this.closeChatType = value
  }

  get nativeClose() {
    return this.native
  }

  set nativeClose(value: boolean) {
    const { close_chat_type } = this.module.model.params

    if (value) {
      if (close_chat_type === 'All') {
        this.module.model.params.close_chat_type = "AllNative"
      }
      if (close_chat_type === 'Media') {
        this.module.model.params.close_chat_type = "MediaNative"
      }
    } else {
      if (close_chat_type === 'AllNative') {
        this.module.model.params.close_chat_type = "All"
      }
      if (close_chat_type === 'MediaNative') {
        this.module.model.params.close_chat_type = "Media"
      }
    }

    this.native = value
  }

  get isCloseChatIntervalSet() {
    const { from, to } = this.module.model.params.close_chat_interval

    return from !== null || to !== null
  }

  get tariffTag() {
    return this.module.tariffTags()
  }

  resetInterval() {
    this.module.model.params.close_chat_interval.from = null
    this.module.model.params.close_chat_interval.to = null
  }

  initIsNative() {
    const { close_chat_type } = this.module.model.params

    this.nativeClose = close_chat_type === 'MediaNative' || close_chat_type === 'AllNative'
  }

  initCloseType() {
    const { close_chat_type } = this.module.model.params

    if (close_chat_type === "All" || close_chat_type === "AllNative") {
      this.closeChatType = 'All'
    }
    if (close_chat_type === "Media" || close_chat_type === "MediaNative") {
      this.closeChatType = 'Media'
    }
  }

  mounted() {
    this.initIsNative()
    this.initCloseType()

    if (this.tariffTag) {
      this.$baseTemplate.saveButton.hide()
    } else {
      this.$baseTemplate.saveButton.show()
    }
  }
}
